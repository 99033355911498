.settings {
    display: flex;
    justify-content: center;
  }
  
.settingsWrapper {
  padding: 20px;
}

.settingsTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.settingsUpdateTitle {
  font-size: 30px;
  margin-bottom: 20px;
  color: lightcoral;
}

.settingsDeleteTitle {
  color: red;
  font-size: 12px;
  cursor: pointer;
}

.settingsForm {
  display: flex;
  flex-direction: column;
}

.settingsForm > label {
  font-size: 20px;
  margin-top: 20px;
}

.settingsForm > input {
  color: gray;
  margin: 10px 0;
  height: 40px;
  border: none;
  border-bottom: 1px solid lightgray;
  border-radius: 10px;
  padding: 8px;
}

.settingsSubmit{
    width: 150px;
    align-self: center;
    border: none;
    border-radius: 10px;
    color: white;
    background-color: teal;
    padding: 10px;
    margin-top: 20px;
    cursor: pointer;
}

.settingsDelete{
  display: flex;
  width: 150px;
  justify-content: center;
  align-self: center;
  border: none;
  border-radius: 10px;
  color: white;
  background-color: red;
  padding: 10px;
  margin-top: 20px;
  cursor: pointer;
}