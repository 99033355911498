ins.adsbygoogle[data-ad-status="unfilled"] {
    display: none !important;
    margin: none !important;
}

.adcontainer{
    width: 100%;
    overflow: hidden;
    min-height: 90px;
}
