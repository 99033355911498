.circle-spinner-overlay {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
    
  .loader {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
        border: 4px solid #f44336;
        border-left-color: transparent;
    }
    50%{
      transform: rotate(180deg);
      border: 4px solid #673ab7;
      border-left-color: transparent;
    }
    100% {
      transform: rotate(360deg);
      border: 4px solid #f44336;
    border-left-color: transparent;
    }
    } 
  