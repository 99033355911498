.write {
  padding-top: 50px;
}

.writeImg {
  margin-left: 150px;
  width: 70vw;
  height: 250px;
  border-radius: 10px;
  object-fit: cover;
}

.writeForm {
  position: relative;
}

.writeFormGroup {
  margin-left: 150px;
  display: flex;
  align-items: center;
}

.writeIcon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: rgb(121, 118, 118);
  cursor: pointer;
}

.writeInput {
  resize: none;
  font-size: 30px;
  border: none;
  padding: 20px;
  width: 70vw;
}

.writeInput:focus {
  outline: none;
}

.writeText {
  font-size: 27px;
  height: 100vh;
}

.writeSubmit {
  position: absolute;
  top: 20px;
  right: 50px;
  color: white;
  background-color: teal;
  padding: 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
}

.categoryItem {
  cursor: pointer;
  padding: 0.5rem 1rem;
}

.categoryItem:hover {
  background-color: #f0f0f0;
}

.categoryItem.selected {
  background-color: #007bff;
  color: #fff;
}

.categoryItem.selected:hover {
  background-color: #007bff;
}

.categoryItem.selected span {
  margin-left: 0.5rem;
  color: #fff;
}

.togg {
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  color: gray;
}

@media only screen and (max-width: 768px) {
  .writeImg {
    margin-left: 0;
    width: 100%;
    height: auto;
  }

  .writeFormGroup {
    margin-left: 0;
    max-width: 100%;
  }

  .writeInput {
    width: 100vw;
  }

  .writeInput,
  .writeText {
    font-size: 20px;
  }
}
