.homePage{
    display: flex;
    flex-direction: column;
}
  
.spinner {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
}
  
.btn {
    width: fit-content; 
    margin: 0 auto; 
    font-size: 14px;
    font-weight: bold;
    border: none;
    background: linear-gradient(45deg, red, yellow); 
    cursor: pointer;
    color: white; 
    border-radius: 5px; 
    padding: 10px 20px; 
    margin-bottom: 10px;
}


.left-container{
    display: flex;
    flex: 9;
    flex-wrap: wrap;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    list-style: none;
    padding: 0;
    overflow: hidden;
  }
  
  .pagination li {
    display: inline-block;
    margin: 0 5px;
  }
  
  .pagination a {
    color: #333;
    text-decoration: none;
    padding: 3px 7px;
    border: 1px solid #707070;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .pagination a:hover {
    background-color: #eee;
  }
  
  .pagination .active a {
    background-color: #3498db;
    color: #fff;
  }
  
  .pagination .disabled a {
    color: #ccc;
    cursor: not-allowed;
  }
  
  .pagination-container {
    display: flex;
    justify-content: center;
  }

  .post-pages{
    justify-content: center;
    display: flex;
    width: 100%;
  }

  /* post related css */

  .posts{
    display: flex;
    flex-wrap: wrap;
    margin: 20px;
    margin-top: 0;
    justify-content: center;
}
.post {
  width: 367px;
  margin: 0px 15px 40px 25px;
}

.postImg {
  max-width: 96%;
  max-height: 207px;
  object-fit: cover;
  border-radius: 7px;
  margin-left: 8px;
  margin-top: 8px;
}

.postImg:hover{
  transform: scale(0.96);
  transition: transform 0.3s ease;
}


.post-image{
  background-color: white;
  margin-bottom: 8px;
  box-shadow: 8px 7px 8px 0 rgba(0,0,0,0.2), 0 12px 20px 0 rgba(0,0,0,0.3);
  height: 222px;
  width: 100%;
  border-radius: 9px;
}

.postInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.postCats{
  margin: 5px;
}

.postCat {
  font-size: 15px;
  color: #ff2a6b;
  line-height: 23px;
  margin-top: 15px;
  margin-right: 6px;
  cursor: pointer;
  font-weight: 600;
}

.postTitle {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 1px;
    line-height: 1.5;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); 
    color: darkred;
}

.postDate {
  font-size: 15px;
  color: #ff2a6b;
  margin-top: 5px;
}

.postDesc {
  font-size: 14px;
  color: #444;
  line-height: 24px;
  margin-top: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -moz-user-select: none;  
  -ms-user-select: none; 
  user-select: none; 
}

.firstad{
  margin-bottom: 22px !important;
}
  
  .none{
    display: none;
  }


@media screen and (max-width: 768px) {
  .postImg{
    pointer-events: none;
    max-height: 155px;
    max-width: 92%;
    margin-left: 12px;
    margin-top: 13px;
  }

.post-image{
  height: 180px;
}
  .postDesc{
    user-select: none;
    width: 95%;
  }
  .postDate{
    margin-bottom: 17px;
  }
  .post{
    width: 304px;
    margin: 0px;
  }
  .googleAdContainer{
    display:flex;
    margin: 2px 0px;
    justify-content: center;
    overflow: hidden;
  }
}

  