@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');

html,
body,
#root {
  height: 100%;
}
body{
  font-family: 'Josefin Sans', 'sans-serif';
  background-color: lavenderblush;
}
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrapper {
  flex: 1;
}
.googleAdContainer{
  display:flex;
  margin: 3px 0px;
  justify-content: center;
  width: 100%;
}
.svg{
  width: 24px;
  height: auto;
}