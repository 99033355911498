.pnf {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .pnf-title {
    font-size: 6rem;
    margin-bottom: 1rem;
  }
  
  .pnf-heading {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  
  .pnf-btn {
    padding: 0.8rem 1.5rem;
    background-color: #4a90e2;
    color: #fff;
    font-size: 1rem;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    margin: 27px 0px;
  }
  
  .pnf-btn:hover {
    background-color: #3066b5;
  }
  .pnf-image{
    height: 480px;
    margin-top: 20px;
  }
  
@media screen and (max-width: 768px) {
  .pnf-image{
    height: 314px;
    margin-bottom: 38px;
  }
  
}
