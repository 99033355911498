.heaeder-section {
    width: 100%;
    height: 50px;
    background-color: white;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    font-family: "Josefin Sans", sans-serif;
    z-index: 999;
  }
  
  .header-top{
    background: darkred;
    overflow: hidden;
    padding: 15px 30px;
  }

  .header-social{
    float: left;
  }

.header-social a {
    color: #fff;
    margin-right: 25px;
}

img:hover{
  color: #000;
}

.user-panel {
  float: right;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
} 

.header-bottom {
  background-color: white;
}

.login-register{
  margin-right: 5px;
  font-size: 18px;
  font-weight: 300;
  cursor: pointer;
}

.spacing{
  padding: 2px 27px;
}
.logo{
  display: flex;
  flex-direction: column;
}

.main-logo{
  font-size: 40px;
  font-family: 'Gambetta', serif;
  letter-spacing: -2px;
  transition: 700ms ease;
  font-variation-settings: "wght" 311;
  margin-bottom: 0.8rem;
  color: black;
  outline: none;
  text-align: center;
}

.main-logo:hover {
  font-variation-settings: "wght" 582; 
  letter-spacing: 0.5px;
  color: darkred;
}

.nav-link{
  font-size: 18px;
  color: black;
}

.nav-link:hover {
  color: #ff2a6b;
}

.userlogo{
  display: flex;
}
.user-name{
  display: flex;
  margin: 4px;
  justify-content: center;
  text-decoration: none;
  color: white;
}

.user-name-div{
  display: flex;
}

  .topIcon {
    font-size: 20px;
    margin-right: 10px;
    cursor: pointer;
  }
  
  .topImg {
    width: 40px;
    height: 34px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
    margin-top: -5px;
  }
  
  .topList {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;
    margin-left: 4px;
  }
  
  .topListItem {
    margin-right: 20px;
    font-size: 18px;
    font-weight: 300;
    cursor: pointer;
  }
  
  .topSearchIcon {
    font-size: 18px;
    color: #666;
    cursor: pointer;
    margin-left: 15px;
  }
  .navbar-toggler{
    border: 1px solid #00000066;
  }
  
  @media screen and (max-width: 768px) {
      .main-logo{
        font-size: 37px;
      }
      .header-social a{
        margin-right: 4px;
      }

      .header-top{
        padding: 15px 20px;
      }

      .topLeft{
        display: none;
      }
      .topListItem:nth-child(2) {
        display: none;
      }
  }