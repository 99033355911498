*{
  margin: 0;
} 

.link {
  text-decoration: none;
  color: inherit;
}

.link:hover{
  color: gray;
}