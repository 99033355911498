.search-post {
  width: 385px;
  margin: 0px 19px 40px 16px;
}

.search-main-element{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.icon{
  height: 32px;
  width: 25px;
}

.submit{
  border: 1px solid black;
  background: none;
}

.search-form {
  padding-right: 42px;
}

.search-input-container {
  position: relative;
}

.search-input-container input {
  padding-right: 48px; 
}

.search-input-container button {
  position: absolute;
  right: 0px; 
  top: 50%;
  transform: translateY(-50%);
}


.search-postImg {
  max-width: 96%;
  max-height: 207px;
  object-fit: cover;
  border-radius: 7px;
  margin-left: 0px;
  margin-top: 8px;
}

.search-postImg:hover{
  transform: scale(0.96);
  transition: transform 0.3s ease;
}

.search-result{
  font-size: 28px;
  margin-bottom: 20px;
  font-weight: bolder;
  margin-top: 20px;
}

.search-post-image{
  background-color: white;
  margin-bottom: 8px;
  box-shadow: 8px 7px 8px 0 rgba(0,0,0,0.2), 0 12px 20px 0 rgba(0,0,0,0.3);
  height: 222px;
  width: 100%;
  border-radius: 9px;
}

.search-postInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-postCats{
  margin: 5px;
}

.search-postCat {
  font-size: 15px;
  color: #ff2a6b;
  line-height: 23px;
  margin-top: 15px;
  margin-right: 6px;
  cursor: pointer;
  font-weight: 600;
}

.search-postTitle {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 1px;
    line-height: 1.5;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); 
    color: darkslateblue;
}

.search-postDate {
  font-size: 15px;
  color: #ff2a6b;
  margin-top: 5px;
}


@media screen and (max-width: 768px) {
  .search-postImg{
    pointer-events: none;
    max-height: 155px;
    max-width: 92%;
    margin-left: 0px;
    margin-top: 13px;
  }

  .search-post-image{
    height: 180px;
  }
  .search-postDate{
    margin-bottom: 17px;
  }
  .search-post{
    width: 304px;
    margin: 0px;
  }
  .search-form{
    padding-left: 42px;
  }
}