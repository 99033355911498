.login {
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255, 0.5)
      ),
      url("https://images.pexels.com/photos/768473/pexels-photo-768473.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500");
    background-size: cover;
  }

  .register {
    background: linear-gradient(
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255, 0.5)
      ),
      url("https://images.pexels.com/photos/317355/pexels-photo-317355.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940");
  }
  
  .loginTitle {
    font-size: 50px;
  }
  
  .loginForm {
    display: flex;
    flex-direction: column;
  }
  
  .loginForm > label {
    margin: 10px 0;
  }
  
  .password {
    padding-right: 49px !important;
  }
  .loginInput {
    padding: 10px;
    background-color: lightgray;
    border: none;
    border-radius: 10px;
  }
  
  .loginButton {
    margin-top: 20px;
    cursor: pointer;
    background-color: lightcoral;
    border: none;
    color: white;
    border-radius: 10px;
    padding: 10px;
  }
  
  .loginButton:disabled{
    cursor: not-allowed;
    background-color: rgb(252, 173, 173);
  }

.passwordInputContainer {
  position: relative;
}

.showPasswordButton {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: #555;
}

.showPasswordButton:hover {
  color: #333;
}

.background{
  background-color: white;
  padding: 20px;
  border-radius: 10px;
}



  