.single-main{
  width: 100%;
  height: 100%;
}
.singlePost {
  flex: 9;
}
  
.singlePostWrapper {
  gap: 11px;
  padding: 11px 20px;
  padding-right: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  
}
.singleImgWrapper {
  gap: 11px;
  padding-right: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
}

.singlePostCats {

  font-size: 19px;
  color: #811515;
  line-height: 20px;
  margin-bottom: 14px;
  margin-right: 10px;
  cursor: pointer;
}

.singlePostTitle {
  font-size: 37px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 1px;
  line-height: 1.5;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  color: darkred;
  min-height: 80px;
}

.singleRelated {
  text-align: center;
  font-size: 28px;
  color: white;
  padding: 11px;
  font-weight: 600;
  margin: 10px 0px 15px 0;
  box-shadow: 8px 7px 8px 0 rgba(0,0,0,0.2), 0 12px 20px 0 rgba(0,0,0,0.3);
  height: 61px;
  width: 98%;
  border-radius: 9px;
  background-color: crimson;
}

.related{
  margin-bottom: 10px;
}

.singlePostTitleInput {
margin: 10px;
font-size: 28px;
text-align: center;
border: none;
color: gray;
border-bottom: 1px solid lightgray;
}

.singlePostTitleInput:focus {
outline: none;
}

.singlePostImgInput {
  margin: 10px;
  font-size: 28px;
  text-align: center;
  border: none;
  color: gray;
  border-bottom: 1px solid lightgray;
}

.singlePostDate{
  margin-right: 17px;
}

.singlePostImgInput:focus {
outline: none;
}

.singlePostEdit {
  float: right;
  font-size: 16px;
  margin-right: 17px;
}

.singlePostIcon {
margin-left: 10px;
cursor: pointer;
}

.singlePostInfo {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #811515;
}

.singlePostDesc {
  color: indigo;
  font-size: 26px;
  line-height: 36px;
  white-space: pre-line;
  margin-top: 10px;
  user-select: none;
}

.desc-container{
  width: 100%;
}

.disclaimier{
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 34px;
}

.singlePostDescInput {
  border: none;
  color: #666;
  font-size: 18px;
  line-height: 25px;
  height: 100vh;
  resize: none;
}

.descpara{
    display: flex;
    padding-left: 15%;
    width: 100%;
}

.singlePostDescInput:focus {
outline: none;
}


.singlePostButton {
  width: 100px;
  border: none;
  background-color: teal;
  padding: 5px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  align-self: flex-end;
  margin-top: 20px;
}

.singlePostLink{
  margin: 10px;
  font-size: 28px;
  text-align: center;
  border: none;
  color: gray;
  border-bottom: 1px solid lightgray;
}

.singlePostLink:focus {
  outline: none;
  }
  /* youtube place holder image */
  .singlePostVideoLink {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 9px;
    width: 50%;
    box-shadow: 8px 7px 8px 0 rgba(0,0,0,0.2), 0 12px 20px 0 rgba(0,0,0,0.3);
    height: 400px;
    margin-bottom: 20px;
  }
  
  .singlePostFrame{
    height: 330px;
    width: 89%;
    border-radius: 10px;
  }
  
.play-button {
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.single-related-image{
  background-color: white;
  margin-bottom: 8px;
  box-shadow: 8px 7px 8px 0 rgba(0,0,0,0.2), 0 12px 20px 0 rgba(0,0,0,0.3);
  height: 233px;
  width: 97%;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.relatedPostsContainer {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 15px;
  margin: 16px 4px 16px 4px;
}

.relatedPostLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #333;
}

.relatedPostImg {
  width: 100%;
  max-height: 200px;
  object-fit: cover;
  border-radius: 8px;
}
.relatedPostLazy {
  width: 95%;
  max-height: 200px;
  object-fit: cover;
  border-radius: 8px;
}

.relatedPostImg:hover{
  transform: scale(0.96);
  transition: transform 0.3s ease;
}

.relatedPostTitle {
  margin-top: 24px;
  font-size: 21px;
  text-align: center;
  line-height: 1.4;
}

.spin{
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 850px) {
  .relatedPostsContainer {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    margin: 16px 0px 16px 0px;
  }
  .related-post-image-title{
    width: 95%;
  }
  .singlePostVideoLink{
    width: 70%;
  }
  .disclaimier{
    font-size: 24px;
  }
}
@media screen and (max-width: 560px) {

  .disclaimier{
    font-size: 16px;
  }
  .descpara{
    padding-left: 5%;
    padding-right: 9px;
  }

  .singlePostWrapper {
    padding: 10px;
  padding-right: 0;

  } 
  .singlePostTitle {
    font-size: 24px;
    margin-right: 9px;
  }

  .singlePostTitleInput {
    font-size: 22px;
  }

  .singlePostInfo {
    font-size: 14px;
  }

.singlePostDesc {
  font-size: 21px;
  line-height: 31px;
}

.singlePostDescInput {
  font-size: 16px;
}

.related{
  height: 48px;
}

.related2{
  height: 75px;
}

.singlePostButton {
  width: 80px;
  padding: 3px;
  font-size: 12px;
  margin-top: 10px;
}
.singlePostEdit{
  margin-top: 22px;
}

.singlePostVideoLink {
  width: 91%;
  height: 247px;
}
  .singlePostFrame{
    height: 200px;
    width: 89%;
    border-radius: 10px;
  }

.relatedPostsContainer {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 20px;
  margin: 16px 0px 16px 0px;
}
.relatedPostLazy{
  max-width: 92%;
}
.relatedPostImg{
  max-width: 100%;
}
.singleRelated{
  font-size: 23px;
  padding: 7px;
}
}