.footer-section {
  padding: 32px 70px 50px;
  /* background-image: url("diwali.jpg"); */
}

.set-bg{
  color: white;
  background-color: darkred;
}

.container-fluid{
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}


.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.footer-social {
  margin-top: 25px;
}

.footer-social a {
  color: white;
  font-size: 14px;
  margin-right: 20px;
}

.youtube:hover{
  color: red;
}
.facebook:hover{
  color: #1877F2;
}
.instagram:hover{
  color: #ff2a6b;
}
.linkedin:hover{
  color:#0A66C2;
}
.twitter:hover{
  color: #1DA1F2;
}


.copyright {
  margin-bottom: 0;
  padding-top: 15px;
  padding-left: 45px;
}

.footer-menu {
  float: none;
  margin-right: 0;
  margin-top: 36px;
}

.footer-menu li a {
  padding: 20px 15px;
}

.footer-menu li a:hover {
  color: orange;
}


.main-menu li, .footer-menu li {
  display: inline;
}

.footer-main-logo{
  font-size: 40px;
  font-family: 'Gambetta', serif;
  letter-spacing: -2px;
  transition: 700ms ease;
  font-variation-settings: "wght" 311;
  margin-bottom: 0.8rem;
  outline: none;
  width: 50%;
  text-decoration: none;
  color: white;
}

.footer-main-logo:hover {
  font-variation-settings: "wght" 582; 
  letter-spacing: 0.5px;
  color: white;
}
@media screen and (min-width: 992px) {
  .copyright{
    display: flex;
  }
}


@media screen and (max-width: 992px) {
  .footer-section{
    padding: 27px 12px 36px;
    text-align: center;
  }
  .footer-menu {
    margin: 30px 0 10px;
    padding-left: 0;
}
.footer-main-logo{
  width: 100%;
}
.footer-menu li a {
  padding: 7px 8px;
}
.footer-social {
  margin-top: 0px;
  margin-left: 32px;
}
.footer-social a{
  margin-right: 10px;
}
.copyright{
  padding-left: 0;
}

}