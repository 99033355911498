.Header{
    min-height: 210px;
}
.carousel {
    max-height: 400px; 
    margin-bottom: 230px;
  }
  
.carousel img {
    max-height: 600px;
    width: auto; 
    margin: 0 auto; 
    margin-bottom: 2px;
}

.carousel-control-next, .carousel-control-prev {
top: 50%;
}

@media screen and (max-width: 768px) {
    .carousel{
        margin-bottom: 0;
    }
    .carousel-control-next, .carousel-control-prev {
        top: 0;
    }
  }