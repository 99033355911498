.privacy-policy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px;
    background-color: #f8f8f8;
    border-radius: 4px;
  }
  
  .privacy-policy-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .privacy-policy-subtitle {
    font-size: 18px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .privacy-policy-text {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 15px;
  }
  
  .privacy-policy-list {
    margin-left: 20px;
  }
  
  .privacy-policy-list li {
    margin-bottom: 5px;
  }
  
  /* Additional styling for links */
  .privacy-policy-text a {
    color: #007bff;
    text-decoration: none;
  }
  
  .privacy-policy-text a:hover {
    text-decoration: underline;
  }