.about {
  max-width: 600px;
  margin: 0 auto;
  padding: 40px;
  text-align: center;
  line-height: 1.6;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

p {
  font-size: 16px;
  margin-bottom: 15px;
}

.subscribe-button {
  margin-top: 30px;
}

.subscribe-button a {
  display: inline-block;
  padding: 10px 20px;
  background-color: #ff0000;
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.subscribe-button a:hover {
  background-color: #cc0000;
}

.sideImg{
  max-height: 250px;
  max-width: 250px;
  object-fit: cover;
  margin-bottom: 12px;
  border-radius: 7px;
}
